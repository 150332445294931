<template>
    <modal-content ref="add-folder" name="add-folder" title="Add Folder" width="480px" :showFooter="false" @beforeOpen="onBeforeOpen">
        <div class="px-6 py-4 bg-card-bg">
            <div>
                <label for="folder-name" class="block mb-2 text-sm font-medium text-gray-900">Folder Name <span class="text-red-600">*</span></label>
                <input type="text" placeholder="Folder Name" id="folder-name" class="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-primary focus:border-primary" v-model.trim="folderName" />
                <div class="text-xs text-red-500 mt-0.5" v-if="$v.folderName.$anyError">Folder Name is required</div>
            </div>
            <div class="mt-4">
                <label for="description" class="block mb-2 text-sm font-medium text-gray-900">Folder Description</label>
                <textarea id="description" v-model="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary focus:border-primary" placeholder="Description"></textarea>
            </div>
            <button type="button" class="mt-4 text-white bg-primary hover:bg-primary-focus font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 w-full" @click="onAddFolder()">Add</button>
        </div>
    </modal-content>
</template>

<script>
import ModalContent from "@shared/modal-content";
import { required } from "vuelidate/lib/validators";

export default {
    name: "AddFolder",
    data() {
        return {
            folderName: "",
            description: "",
        };
    },
    validations: {
        folderName: {
            required,
        },
    },
    components: {
        ModalContent,
    },
    methods: {
        showModal() {
            this.$refs["add-folder"].showModal();
        },
        onAddFolder() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$emit("on-add-folder", { name: this.folderName, description: this.description });
                this.$nextTick(() => {
                    this.$refs["add-folder"].hideModal();
                });
            }
        },
        onBeforeOpen() {
            this.folderName = "";
            this.description = "";
            this.$v.$reset();
        },
    },
};
</script>

<style lang="scss" scoped></style>
